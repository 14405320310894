import React, { useEffect } from "react";

const LiveChat = () => {
    useEffect(() => {
        // Create the script tag
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.id = "zsiqscript";
        script.src = "https://salesiq.zohopublic.in/widget?wc=siq3fdecec371634da71c1b85500dc1883c4168ff5ca2ae19b5fe2a2f72c1229513";
        script.defer = true;

        // Initialize Zoho SalesIQ
        const zohoScript = document.createElement("script");
        zohoScript.type = "text/javascript";
        zohoScript.async = true;
        zohoScript.innerHTML = `
            window.$zoho=window.$zoho || {};
            $zoho.salesiq = $zoho.salesiq || {ready: function() {}};
        `;

        // Append the scripts to the body
        document.body.appendChild(zohoScript);
        document.body.appendChild(script);

        // Cleanup on unmount
        return () => {
            document.body.removeChild(zohoScript);
            document.body.removeChild(script);
        };
    }, []);

    return null; // No UI element needed for the widget
};

export default LiveChat;
