import { data } from 'jquery';
import React, { useEffect } from 'react'
//  let MAINURL = " https://gctih9ngk8.execute-api.ap-south-1.amazonaws.com/uat/api";

// let MAINURL = "https://6sfv3w8k2i.execute-api.ap-south-1.amazonaws.com/prod/api";


let MAINURL = "https://api.elakiyaabestjobs.com/api";

// let MAINURL = "http://localhost:5166/api";
// let MAINURL = "http://localhost:36835/api";

export const apiURL = MAINURL;

let Res = "";

var d= new Date;

let month = d.getMonth()+1;
if(month <10){
    month ='0'+month;
}

let second= d.getSeconds();
if(second<10){
    second = '0'+second;
}
var Hour =d.getHours() <10 ?'0'+d.getHours():d.getHours();
var date =d.getDate() <10 ?'0'+d.getDate():d.getDate()
var minute=d.getMinutes() < 10 ? '0'+d.getMinutes():d.getMinutes()

let curdate =d.getFullYear()+'-'+month+'-'+date+'T'+ Hour + ":" 
    +minute+ ":" +second+".965Z";

///const proxyurl = "https://cors-anywhere.herokuapp.com/";
// const proxyurl = "";

async function BindGrid(params, url) {

    var MCreateby = parseInt(sessionStorage.getItem("MUserID"))
    const url1 = MAINURL + url;
    let Res = "";
    try {
        const response = await fetch(url1, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...params,"createdBy": MCreateby,
                "createdDate":curdate,
                "AccessType":sessionStorage.getItem("AccessType"),
               
                
             
               
            }),
        });

        let string = await response.text();

        var L = JSON.parse(string);
        Res = JSON.parse(L.values);

    }
    catch {

    }

    return Res;
}
async function DirectApiCall(Flag,parms,value) {
 
    let url1 =  "";
    let Res = "";
   var meth="POST"
   let response ="";
        if(Flag=="country"){
            url1="https://countriesnow.space/api/v0.1/countries";
            meth="GET"
        } 
        if(Flag=="state"){
            var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "country": parms
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

response = await fetch("https://countriesnow.space/api/v0.1/countries/states", requestOptions);
        }
        if(Flag=="city"){
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            
            var raw = JSON.stringify({
              "country":parms,
              "state": value
            });
            
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };
            
            response = await  fetch("https://countriesnow.space/api/v0.1/countries/state/cities", requestOptions)
              }
        try {
if(meth=="GET"){
    response = await fetch(url1, {
        method: meth,                
    });
}
// else{
//     response = await fetch(url1, {
//         method: meth,  
//         body: JSON.stringify({...parms
//         })              
//     });
// }
            
    
            let string = await response.text();

            var L = JSON.parse(string);
            Res = L.data;
    
     
    
        }
        catch {
    
        }    

    

    return Res;
}

async function CheckLogin(params, url) {

    var MCreateby = parseInt(sessionStorage.getItem("MUserID"))
    const url1 =  MAINURL + url;
    let Res = "";
    try {
        const response = await fetch(url1, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...params,"createdBy": MCreateby,
                "createdDate": curdate
            }),
        });

        let string = await response.text();

        var L = JSON.parse(string);
        Res = L.values;

    }
    catch {

    }

    return Res;
}

async function BindDropdown(url) {

    var MCreateby = parseInt(sessionStorage.getItem("MUserID"))
    const url1 =  MAINURL + url;
    let Res = "";
    try {
        const response = await fetch(url1, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },

        });

        let string = await response.text();

        var L = JSON.parse(string);
        Res = JSON.parse(L.values);

    }
    catch {

    }

    return Res;
}

async function IUD(params, url) {
    const url1 =  MAINURL + url;
    let res = "";
    var MCreateby = parseInt(sessionStorage.getItem("MUserID"))
    try {

        const response = await fetch(url1, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...params,"createdBy": MCreateby,
                "createdDate": curdate
            }),
        });

        let string = await response.text();
        var L = JSON.parse(string);
        Res = L.values;
    }
    catch {

    }

    return Res;
}

async function IUD1(params, url) {
    const url1 =  MAINURL + url;
    let res = "";
    var MCreateby = parseInt(sessionStorage.getItem("MUserID"))
    try {

        const response = await fetch(url1, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...params,"createdBy": MCreateby,
                "createdDate": curdate
            }),
        });

        let string = await response.text();
        Res = string;
    }
    catch {

    }

    return Res;
}
async function NoDirect(url) {
    const url1 =  MAINURL + url;
    let res = "";
    var MCreateby = parseInt(sessionStorage.getItem("MUserID"))
    try {

        const response = await fetch(url1, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "createdBy": MCreateby,
                "createdDate": curdate
            }),
        });

        let string = await response.text();
        Res =JSON.parse(string);
    }
    catch {

    }

    return Res;
}
async function IUDFormData(params, url) {
    const url1 = MAINURL + url;
    const response = await fetch(url1, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: params,
    });

    const string = await response.text();
    const json = string === "" ? response : JSON.parse(string);
    return json;
}



let menu = sessionStorage.getItem("roleaccess") == null ? JSON.parse(sessionStorage.getItem("roleaccess")) : 0;

function checkmenu(id) {
    return menu.filter(function (menu) {
        return menu.MenuID == id;

    });
}

function CheckView(ID){
    let _x=0;
    try{

     _x=JSON.parse(sessionStorage.getItem('roleaccess')).filter(val => val.MenuID==ID)[0].View;
    }
    catch{

    }
    return _x;
}
function CheckAdd(ID){
    let _x=0;
    try{

     _x=JSON.parse(sessionStorage.getItem('roleaccess')).filter(val => val.MenuID==ID)[0].Add;
    }
    catch{

    }
    return _x;
}
function CheckUpdate(ID){
    let _x=0;
    try{

     _x=JSON.parse(sessionStorage.getItem('roleaccess')).filter(val => val.MenuID==ID)[0].Edit;
    }
    catch{

    }
    return _x;
}
function CheckDelete(ID){
    let _x=0;
    try{

     _x=JSON.parse(sessionStorage.getItem('roleaccess')).filter(val => val.MenuID==ID)[0].Delete;
    }
    catch{

    }
    return _x;
}

export default {
    BindGrid, BindDropdown,
    IUD,
    IUD1,
    MAINURL,
    IUDFormData,NoDirect,
    checkmenu, CheckLogin,CheckAdd,CheckUpdate,CheckDelete,CheckView,
    DirectApiCall
}

