import React, { useState, useEffect } from 'react'
import Base from '../../Base';
import { Link, useNavigate } from "react-router-dom";
import Api, { apiURL } from '../../Services/ApiCall';
import Swal from 'sweetalert2';
import { useLocation } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';


export default function PayoutForm() {


    const location = useLocation();
    const { ID, Flag } = location.state;
    const navigate = useNavigate();
    const [amount, setAmount] = useState("");


    async function IUDOperation(Flag, ID) {
        var Amount = amount;

        if (Amount == "") {
            toast.error('Please enter Amount')
            return false;
        }
        var payload = {
            "mstpayoutid": 0,
            "agentCode": JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode'],
            "amount": Amount,
            "approvedby": "",
            "remarks": "",
            "status": "",
            "extra1": "",
            "extra2": "",
            "extra3": "",
            "extra4": "",
            "extra5": "",
            "extra6": "",
            "flag": "I"
        };

        try {
            const response = await axios.post(`${apiURL}/CommanAPI/CreatePayout`, payload);

            const message = JSON.parse(response.data.values);
            showAlert(message.Message)
            setTimeout(() => {
                navigate('/PayoutTable')
            }, 2000)
        } catch (error) {
            console.error('Error making POST request:', error);
            showAlert('Error making POST request', 'error');
        }
    }
    const showAlert = (_response) => {
        if(_response=="Your wallet amount is too low."){
            Swal.fire({
                title: "Failed",
                text: _response,
                icon: "failed",
                confirmButtonText: "OK",
            });
        }else{
            Swal.fire({
                title: "Success",
                text: _response,
                icon: "success",
                confirmButtonText: "OK",
            });
        }
        
    };


    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <Base />
            <div id="main" class="main">
                <div class="pagetitle">
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                            <li class="breadcrumb-item">Registration</li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Payout Creation</h5>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="Amount">Request Amount</label>
                                <input type="number" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={amount} placeholder="Amount" onChange={(e) => setAmount(e.target.value)} />
                            </div>
                            <div class="text-center">
                                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                                <button type="reset" class="btn btn-info">
                                    <Link to='/PayoutTable'>
                                        Back
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
